@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Squada+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&display=swap');



.heading{
    font-family: 'roboto', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    color: #000;
    border-left: #ED4852 5px solid;
    padding-left: 10px;
}

.container-bg{
    background: linear-gradient(135deg, #F9B4C287, #F9B4C287);
}

.left-bg{
    background: linear-gradient(135deg, #FFAA85, #B3315F);
    /* margin-right: -200px; */
    z-index: 1;
    /* margin-bottom: 4rem; */
    /* transform: translateX(10rem);  */
}

.left-head-text{
    font-family: 'Epilogue', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    color: #fff;
}
.left-head-text-m{
    font-family: 'Epilogue', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    color: #fff;
}
.left-head-text-2{
    font-family: 'inter', sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
    color: #fff;
}

.menu-text{
    font-family: 'roboto', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    color: #03435D;
    text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.35);
}

.btn-bg{
    background: linear-gradient(135deg, rgba(255, 170, 133, 0.5), rgba(179, 49, 95, 0.5));
    border: none;
}

.right-img{
    margin-right: 200px;
    margin-top: 2rem;
}

.left-item{
    transform: translateX(9rem); 
}

.dot-img{
    position: absolute;
    bottom: 0;
    margin-bottom: 1rem;
    margin-left: 1rem;
}
  

.left-item-new{
    transform: translateX(3rem); 
}