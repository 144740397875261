.carousel-inner img {
    width: 100%;
    height: auto;
  }
  
  .carousel-caption {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    width: 100%;
  }
  
  .carousel-caption-bg {
    background: rgba(0, 0, 0, 0.3); 
    /* Add a darker background for better text visibility */
    padding: 1rem;
    border-radius: 10px;
    display: inline-block;
    text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.9);
  }

  .carousel-caption-bg-m {
    /* Add a darker background for better text visibility */
    margin: 0;
    border-radius: 10px;
    display: inline-block;
    text-shadow: 4px 5px 6px rgba(0, 0, 0, 0.9);
  }

  .txt-shadow{
    text-shadow: 4px 5px 6px rgba(0, 0, 0, 0.35);
  }
  
  @media (max-width: 768px) {
    .carousel-caption h2 {
      font-size: 2rem;
    }
  
    .carousel-caption p {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 576px) {
    .carousel-caption h2 {
      font-size: 1.5rem;
    }
  
    .carousel-caption p {
      font-size: 0.875rem;
    }
  }