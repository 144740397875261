@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Squada+One&display=swap');


.bg{
    background: linear-gradient(135deg, #f48179, #f057a2);
}

.l-bg{
    background: linear-gradient(160deg, #FFAA85, #B3315F);
}   

.head-font{
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #fff;
}

.para-font{
    font-family: 'Squada One', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    color: #fff;
}

.headings{
    font-family: 'Squada One', sans-serif;
    font-weight: 400;
    font-size: 2.6rem;
    color: #03435D;
}

.btn-align-left {
    background: linear-gradient(160deg, #FFAA85, #B3315F);
    border: none;
}

.main-num{
    font-family: 'Squada One', sans-serif;
    font-weight: 500;
    font-size: 2.7rem;
    color: #000;
    text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);
}
.main-text{
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: 275;
    font-size: 1rem;
    color: #000;
}