.container-bg {
    /* background: linear-gradient(135deg, #F9B4C287, #F9B4C287); */
    background: linear-gradient(135deg, rgba(255, 170, 133, 0.9), rgba(179, 49, 95, 0.9));
    color: #fff;
    border-radius: 10px;
}

.quality-head{
    font-family: 'roboto', sans-serif;
    font-size: 2rem;
    font-weight: 700;
    color: #000;
    padding-left: 10px;
}

.hov:hover{
   scale: 1.1;
   transition: 0.5s ease-in-out;
}

.head-text{
    text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.35);
}