@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Squada+One&display=swap');

.contact{
    font-family: 'roboto', sans-serif;
    font-size: 2.3rem;
    font-weight: 200;
    border-bottom: 7px red solid;
    padding-bottom: 5px;
}

.contact-head{
    font-family: 'roboto', sans-serif;
    font-size: 2rem;
    font-weight: 700;
    color: #03435D;
}
.contact-subhead{
    font-family: 'roboto', sans-serif;
    font-size: 1.5rem;
    font-weight: 100;
    color: #000;
}

.form-head{
    font-family: 'roboto', sans-serif;
    font-size: 2rem;
    font-weight: 700;
    color: #000;
    border-left: 4px red solid;
    padding-left: 10px;
}

.form-bg{
    background: linear-gradient(160deg, #FFAA85, #B3315F);
}