@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Squada+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&display=swap');


.aboutus {
    font-family: 'roboto', sans-serif;
    background: linear-gradient(135deg, rgba(255, 170, 133, 0.5), rgba(179, 49, 95, 0.5));
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.head-text{
    text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.35);
}

.hov:hover{
    scale: 1.05;
    transition: 0.5s;
}